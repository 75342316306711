import { Dispatch, SetStateAction } from "react"
import { TApplicationActiveTab } from "../JsTpviewer"
import CompareIcon from "./icons/CompareIcon"
import MirrorIcon from "./icons/MirrorIcon"

type TSwitchBeforeAfterProps = {
    activeTab: TApplicationActiveTab
    setActiveTab:(value: React.SetStateAction<TApplicationActiveTab>) => void
    setStepIndex:(value: React.SetStateAction<number>) => void
    
    isViewMirrored  : boolean
    setViewMirrored : Dispatch<SetStateAction<boolean>>

}

const SwitchBeforeAfter = (props: TSwitchBeforeAfterProps) => {
    
    const { 
        setActiveTab, 
        setStepIndex, 
        activeTab,
        isViewMirrored,
        setViewMirrored,
    } = props

    return(
        <div id='compare-before-after'>
            
            <div className='button-flex-wrapper'>
                <CompareIcon/>
                
                <div className='text'>
                    Compare before & after
                </div>

                <label className="switch">
                    <input 
                        type="checkbox" 
                        checked={ activeTab === 'BEFORE_AFTER' ? true : false }
                        onChange = {(e) => {
                            if(activeTab=== 'BEFORE_AFTER'){
                                setActiveTab('TIMELINE')
                                setStepIndex(0)
                            }else{
                                setActiveTab('BEFORE_AFTER')
                            }
                        }}
                    />
                    <span className="slider round"/>
                </label>
            </div>

            <div className='button-flex-wrapper'>
                <MirrorIcon />
                <div className='text'>
                    Mirror
                </div>
                <label className="switch">
                <input 
                    type="checkbox" 
                    checked={ isViewMirrored }
                    onChange = {(e) => {
                        setViewMirrored(prev => !prev)
                    }}
                />
                <span className="slider round"/>
            </label>
            </div>
        </div>
    )
}

export default SwitchBeforeAfter
