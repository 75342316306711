import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import { delayValue } from '../JsTpviewer'
import { useEffect, useState } from 'react'
import { Event, SCXML, SingleOrArray } from 'xstate'
import { TMachineStateEvent, useViewerState } from '../Fsm/viewerStateMachine'

type TTimeLineControls = {
    maxSteps     : number
    setStepIndex : React.Dispatch<React.SetStateAction<number>>
    isPlayed     : boolean
    setIsPlayed  : React.Dispatch<React.SetStateAction<boolean>>
    currentIndex : number
    setDelay     : React.Dispatch<React.SetStateAction<number | null>>
    sendViewerEvent : (eventId: SCXML.Event<TMachineStateEvent> | SingleOrArray<Event<TMachineStateEvent>>) => void
}

const TimeLineControls = (props:TTimeLineControls) => {


    const { 
        maxSteps,
        setStepIndex,
        isPlayed,
        setIsPlayed,
        currentIndex,
        setDelay,
        sendViewerEvent,
    } = props
    
    const min = 0
    const max = maxSteps-1
    const [ timeLinePosition, setTimelinePosition ] = useState(currentIndex)

    useEffect(()=>{
        setTimelinePosition(currentIndex)
    },[currentIndex])

    const handlePlay = () =>{
        if(currentIndex + 1 === maxSteps){
            setStepIndex(0)
        }
        setIsPlayed((prev) => {
            if(prev === true){
                setDelay(null)
                sendViewerEvent({type:'event_stop'})
            }else{
                setDelay(delayValue)    
                sendViewerEvent({type:'event_play_view'})
            }
            return(!prev)
        })
    }

    return(
        <div id='timeline-controls'>
            
            <div
                id        = 'play'
                className = 'player-button'
                onClick   = { (e)=>{
                    e.stopPropagation()
                    e.preventDefault()
                    handlePlay()
                } }
            >
                {
                    isPlayed ?
                        <FontAwesomeIcon icon={faPause} color={'#5700ff'}/>
                    :
                        <FontAwesomeIcon icon={faPlay} color={'#5700ff'}/>
                }
            </div>

            <div id='timeline'>
                
                <input 
                    className='control-range' 
                    type="range" 
                    min = { min } 
                    max = { max }
                    value={ timeLinePosition }
                    style={{
                        backgroundSize: (timeLinePosition - min) * 100 / (max - min) + '% 100%'
                    }}
                    onChange = {(e)=>{
                        if(isPlayed === true){
                            handlePlay()
                        }
                        setTimelinePosition(Number(e.target.value))
                        setStepIndex(Number(e.target.value))
                    
                    }}
                />

                <div className='label-wrapper'>
                    <div className='label'>
                        Current smile
                    </div>
                    <div className='label'>
                        New smile
                    </div>
                </div>
            </div>

        </div>
    )

}

export default TimeLineControls
