import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import * as THREE from 'three'
import CameraLightController from '../Scene/CameraLightController'
import { TSteppedModelData, TViewValue } from '../types'
import getZoomValue from '../Utils/get-viewport-zoom-value'
import Information from './Information'
import ViewControls from './ViewControls'
import TimeLineControls from './TimeLineControls'
import SwitchBeforeAfter from './SwitchBeforeAfter'
import { TApplicationActiveTab, TModelStateAccumulator, TViewResetFlag, cameraDistance } from '../JsTpviewer'
import { ResizeObserver } from '@juggle/resize-observer'
import { Event, SCXML, SingleOrArray } from 'xstate'
import { TMachineStateEvent } from '../Fsm/viewerStateMachine'
import ModelViewController from '../Scene/ModelViewController'
import isTouch from '../Utils/isTouch'
import { TVec2 } from './View3dBeforeAfter'
import CameraZoomController from '../Scene/CameraZoomController'


type TView3dTimelineProps = {
    teethsModels    : JSX.Element | undefined
    gingivaModels   : JSX.Element | undefined
    gingivaModelGeometry : TSteppedModelData
    currentView     : TViewValue
    setCurrentView  : Dispatch<SetStateAction<TViewValue>>
    
    isViewClicked   : boolean
    setViewClicked  : Dispatch<SetStateAction<boolean>>
    
    smilewrapperInfo: string | undefined

    stepIndex       : number
    setStepIndex    : Dispatch<SetStateAction<number>>
    
    isPlayed        : boolean
    setIsPlayed     : Dispatch<SetStateAction<boolean>>

    setDelay        : Dispatch<SetStateAction<number | null>>

    activeTab : TApplicationActiveTab
    setActiveTab: Dispatch<SetStateAction<TApplicationActiveTab>>
    modelStateAccumulatorRef: React.MutableRefObject<TModelStateAccumulator>
    prevTabRef: React.MutableRefObject<TApplicationActiveTab>
    isNeedToResetViewRef: React.MutableRefObject<TViewResetFlag>

    isViewMirrored : boolean
    setViewMirrored: Dispatch<SetStateAction<boolean>>

    sendViewerEvent : (eventId: SCXML.Event<TMachineStateEvent> | SingleOrArray<Event<TMachineStateEvent>>) => void
}

const View3dTimeline = (props:TView3dTimelineProps ) =>{

    const {  
        gingivaModelGeometry,
        gingivaModels,
        teethsModels,
        currentView,
        setCurrentView,
        isViewClicked,
        setViewClicked,
        smilewrapperInfo,
        stepIndex,
        setStepIndex,
        isPlayed,
        setIsPlayed,
        setDelay,
        activeTab,
        setActiveTab,
        modelStateAccumulatorRef,
        isNeedToResetViewRef,
        sendViewerEvent,
        isViewMirrored,
        setViewMirrored,
    } = props

    const view3dRef       = useRef<any>(null)

    const isMouseDown      = useRef<boolean>(false)
    const isMouseRightDown = useRef<boolean>(false)
    
    const mouseXYDefault:TVec2 = {x:-1, y:-1}
    const mouseXYStart    = useRef(mouseXYDefault)
    const touchesXYStart  = useRef([mouseXYDefault,mouseXYDefault])    
    const touchesDistanceStart = useRef(0)
    const mouseXYDelta    = useRef({x:0, y:0})
    const zoomWheelValue  = useRef(0)

    

    const controlsMouseDown = useCallback((e:any)=>{
        
        e.preventDefault()
        isMouseDown.current = true

        if(isTouch(e)){
            if(typeof(e.touches)!=='undefined' && e.touches.length === 1){
                mouseXYStart.current = {x: e.touches[0].clientX, y: e.touches[0].clientY }
            }else if(typeof(e.touches)!=='undefined' && e.touches.length === 2){
                isMouseRightDown.current = true
                mouseXYStart.current = {
                    x: (e.touches[0].clientX + e.touches[1].clientX)/2, 
                    y: (e.touches[0].clientY + e.touches[1].clientY)/2 
                }
                touchesXYStart.current = [
                    {x: e.touches[0].clientX, y: e.touches[0].clientY },
                    {x: e.touches[1].clientX, y: e.touches[1].clientY }
                ]

                touchesDistanceStart.current = Math.sqrt(Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2) + Math.pow(e.touches[0].clientY - e.touches[1].clientY,2))


            }else if(typeof(e.clientX)!=='undefined' && typeof(e.clientY)!=='undefined' ){
                mouseXYStart.current = {x: e.clientX, y: e.clientY }
            }
        }else{
            if(e.button === 2){ // RIght Mouse Button Click
                isMouseRightDown.current = true
            }
            mouseXYStart.current = {x: e.clientX, y: e.clientY }
        }
        
    },[])

    const controlsMouseUp = useCallback((e:any)=>{
        
        isMouseDown.current      = false
        isMouseRightDown.current = false
        touchesDistanceStart.current = 0
        mouseXYStart.current = mouseXYDefault
        mouseXYDelta.current = {x:0, y:0}
        touchesXYStart.current = [mouseXYDefault,mouseXYDefault]
        zoomWheelValue.current = 0
    },[])

    const controlsMouseMove = useCallback((e:any)=>{

        if(isMouseDown.current === true){
            if(isTouch(e) === false){
                let deltaXY:TVec2 = {
                    x: mouseXYStart.current.x - e.clientX ,
                    y: mouseXYStart.current.y - e.clientY ,
                }
                mouseXYDelta.current = deltaXY    
            }
            if(isTouch(e) === true){
                // ROTATE VIEW
                if(typeof(e.touches)!=='undefined' && e.touches.length === 1){
                    let deltaXY:TVec2 =
                    {
                        x: mouseXYStart.current.x - e.touches[0].clientX,
                        y: mouseXYStart.current.y - e.touches[0].clientY
                    }
                    mouseXYDelta.current = deltaXY
                } else if(typeof(e.touches)!=='undefined' && e.touches.length === 2){
                    // MOVE MOBILE VIEW
                    let deltaXY:TVec2 =
                    {
                        x: mouseXYStart.current.x - (e.touches[0].clientX+e.touches[1].clientX)/2,
                        y: mouseXYStart.current.y - (e.touches[0].clientY+e.touches[1].clientY)/2
                    }
                    mouseXYDelta.current = deltaXY

                    // ZOOM MOBILE VIEW
                    const touchesDistanceCurrent   = Math.sqrt(Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2) + Math.pow(e.touches[0].clientY - e.touches[1].clientY,2))
                    const touchesDistanceDifference = touchesDistanceCurrent - touchesDistanceStart.current

                    const zoomTouchesDistanceTriggerValue = 80 // px

                    if( touchesDistanceDifference > zoomTouchesDistanceTriggerValue ){
                        zoomWheelValue.current = 1
                    } else if( touchesDistanceDifference < -zoomTouchesDistanceTriggerValue ){
                        zoomWheelValue.current = -1
                    } else{
                        zoomWheelValue.current = 0
                    }
                    
                }
            }
        }else{
            mouseXYDelta.current = {x:0, y:0}
        }
    },[])

    const controlsZoom = useCallback((e:any)=>{
        if(e.deltaY > 0){ zoomWheelValue.current = 1 }
        if(e.deltaY < 0){ zoomWheelValue.current = -1 }
        setTimeout(()=>{zoomWheelValue.current = 0},50)
    },[])

    const contextMenu = (e:any) =>{
        e.preventDefault() // disable rightClick menu
    }



    useEffect(()=>{
        
        if(view3dRef.current){

            view3dRef.current.addEventListener( 'mousemove'  , controlsMouseMove )
            view3dRef.current.addEventListener( 'mousedown'  , controlsMouseDown )
            view3dRef.current.addEventListener( 'mouseup'    , controlsMouseUp   )
            view3dRef.current.addEventListener( 'mouseout'   , controlsMouseUp   )
            view3dRef.current.addEventListener( 'contextmenu', contextMenu       )
            view3dRef.current.addEventListener( 'wheel'      , controlsZoom      )

            view3dRef.current.addEventListener( 'touchmove'  , controlsMouseMove )
            view3dRef.current.addEventListener( 'touchstart' , controlsMouseDown )
            view3dRef.current.addEventListener( 'touchend'   , controlsMouseUp   )
            view3dRef.current.addEventListener( 'touchcancel', controlsMouseUp   )

        }
        return(()=>{
            // view3dRef.current.removeEventListener( 'mousemove', controlsMouseMove )
            // view3dRef.current.removeEventListener( 'mousedown', controlsMouseDown )
            // view3dRef.current.removeEventListener( 'mouseup'  , controlsMouseUp   )
            // view3dRef.current.removeEventListener( 'mouseout' , controlsMouseUp   )
        })
    },[])


    return(
        <>
            <div id='view-3d'
                ref = { view3dRef }
            >
                <Canvas
                    resize={{ 
                        polyfill: ResizeObserver,
                        scroll: true, 
                        debounce: { 
                            scroll: 50, 
                            resize: 0 
                        }
                    }} 
                    gl={{ 
                        antialias: true,
                        autoClearColor: true,
                        toneMapping: THREE.NoToneMapping,
                        alpha: true,
                    }}
                    legacy
                    linear

                    camera={{
                        position: new THREE.Vector3(0,0,cameraDistance),
                        zoom: getZoomValue()
                    }}

                    style={{
                        transform: isViewMirrored ? 'scaleX(-1)' : undefined
                    }}

                    orthographic
                > 
                    <directionalLight name='light'  color= { 0xffffff } intensity={ 1.0 } position={[0,0,10]} />
                    <CameraLightController/>

                    <hemisphereLight 
                        //args={['#888899', '#333344']} // skyColor={ 0x443333 } groundColor={ 0x111122 } intensity
                        args={['#161111', '#161111']}
                        intensity={1}
                    />

                    { teethsModels  }

                    { gingivaModels }

                    <ModelViewController
                        viewType                 = "main"
                        isMouseDown              = { isMouseDown                }
                        isMouseRightDown         = { isMouseRightDown           }
                        delta                    = { mouseXYDelta               }
                        currentView              = { currentView                }
                        isNeedToResetViewRef     = { isNeedToResetViewRef       }
                        modelStateAccumulatorRef = { modelStateAccumulatorRef   }
                        isViewMirrored           = { isViewMirrored             }
                    />

                    <CameraZoomController
                        viewType                 = "main"
                        activeTab            = { activeTab            } 
                        currentView          = { currentView          }
                        zoomWheelValue       = { zoomWheelValue       }
                        isNeedToResetViewRef = { isNeedToResetViewRef }
                    />
                </Canvas>
                
                <img id='label-3d' src="3d.png" alt="3d"/>
                
                <Information smilewrapperInfo = { smilewrapperInfo }/>

            </div>
            
            <div id='controls'>

                <ViewControls 
                    currentView          = { currentView           }
                    setCurrentView       = { setCurrentView        }
                    setViewClicked       = { setViewClicked        }
                    isNeedToResetViewRef = { isNeedToResetViewRef  }
                    viewControl          = 'main'
                />

                <TimeLineControls
                    setStepIndex      = { setStepIndex                           }
                    setIsPlayed       = { setIsPlayed                            }
                    isPlayed          = { isPlayed                               }
                    maxSteps          = { gingivaModelGeometry.upperSteps.length }
                    currentIndex      = { stepIndex                              }
                    setDelay          = { setDelay                               }
                    sendViewerEvent   = { sendViewerEvent                        }
                />
                
                <SwitchBeforeAfter 
                    activeTab       = { activeTab       }
                    setActiveTab    = { setActiveTab    }
                    setStepIndex    = { setStepIndex    }
                    isViewMirrored  = { isViewMirrored  }
                    setViewMirrored = { setViewMirrored }
                />

            </div>
        </>
    )
}


export default View3dTimeline
